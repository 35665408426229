import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { HiMenu, HiX, HiHome, HiUser, HiLogout, HiCurrencyDollar, HiAcademicCap } from 'react-icons/hi';
import LogoComponent from './LogoComponent';
import { StreakPopup } from './StreakPopup';
import { name, serverURL, websiteURL } from '../constants';

const Header = ({ isHome }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [admin, setAdmin] = useState(false);
  const navigate = useNavigate();
  const plan = sessionStorage.getItem('type');
  const isVerified = sessionStorage.getItem('isVerified');

  useEffect(() => {
    if (isHome && !sessionStorage.getItem('uid')) {
      navigate('/signin');
    }
    checkAdminStatus();
  }, [isHome, navigate]);

  const checkAdminStatus = async () => {
    if (sessionStorage.getItem('adminEmail') === sessionStorage.getItem('email')) {
      setAdmin(true);
    } else {
      try {
        const response = await fetch(`${serverURL}/api/dashboard`, {
          method: 'POST',
          credentials: 'include',
        });
        const data = await response.json();
        sessionStorage.setItem('adminEmail', data.admin.email);
        setAdmin(data.admin.email === sessionStorage.getItem('email'));
      } catch (error) {
        console.error('Error checking admin status:', error);
      }
    }
  };

  const handleLogout = () => {
    sessionStorage.clear();
    navigate('/signin');
  };

  const handleTeachMeClick = () => {
    if (plan === 'free') {
      navigate('/trialpayments');
    } else {
      navigate('/create');
    }
  };

  const navItems = isHome ? [
    { name: 'Home', icon: HiHome, onClick: () => navigate('/home') },
    { name: 'Profile', icon: HiUser, onClick: () => navigate('/profile') },
    { name: 'Pricing', icon: HiCurrencyDollar, onClick: () => navigate('/pricing', { state: { header: true } }) },
    { name: 'Logout', icon: HiLogout, onClick: handleLogout },
    ...(admin ? [{ name: 'Admin', icon: HiAcademicCap, onClick: () => window.location.href = `${websiteURL}/dashboard` }] : []),
  ] : [
    { name: 'Pricing', icon: HiCurrencyDollar, onClick: () => navigate('/pricing', { state: { header: false } }) },
    { name: 'Features', icon: HiAcademicCap, onClick: () => navigate('/features') },
    { name: 'Sign In', icon: HiUser, onClick: () => navigate('/signin') },
    { name: 'Sign Up', icon: HiUser, onClick: () => navigate('/signup') },
  ];

  return (
    <header className="bg-gradient-to-r from-blue-500 to-blue-700">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex-shrink-0 flex items-center">
            <Link to={websiteURL} className="flex items-center">
              <LogoComponent isDarkMode={false} />
              <span className="ml-2 text-xl font-bold">{name}</span>
            </Link>
          </div>
          <div className="hidden md:block">
            <div className="ml-10 flex items-baseline space-x-4">
              {navItems.map((item) => (
                <button
                  key={item.name}
                  onClick={item.onClick}
                  className="text-white hover:bg-blue-600 hover:bg-opacity-75 px-3 py-2 rounded-md text-sm font-medium"
                >
                  {item.name}
                </button>
              ))}
              {isHome && isVerified && (
                <button
                  onClick={handleTeachMeClick}
                  className={`px-4 py-2 rounded-md text-sm font-medium ${
                    plan === 'Yearly Plan'
                      ? 'bg-yellow-400 text-blue-900 hover:bg-yellow-500'
                      : plan === 'Monthly Plan'
                      ? 'bg-gray-400 text-blue-900 hover:bg-gray-500'
                      : 'bg-white text-blue-600 hover:bg-blue-50'
                  }`}
                >
                  Now Teach Me
                </button>
              )}
            </div>
          </div>
          <div className="md:hidden flex items-center">
            <StreakPopup position="header" title="" score="" message="" />
            {plan === 'free' && (
              <Link
                to="/payment"
                state={{ plan: 'Yearly Plan' }}
                className="mr-2 bg-yellow-400 text-blue-900 px-3 py-1 rounded-md text-sm font-medium hover:bg-yellow-500"
              >
                Update Plan
              </Link>
            )}
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            >
              {isMenuOpen ? (
                <HiX className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <HiMenu className="block h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>
      {isMenuOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {navItems.map((item) => (
              <button
                key={item.name}
                onClick={() => {
                  item.onClick();
                  setIsMenuOpen(false);
                }}
                className="text-white hover:bg-blue-600 hover:bg-opacity-75 block px-3 py-2 rounded-md text-base font-medium"
              >
                <item.icon className="inline-block mr-2 h-5 w-5" />
                {item.name}
              </button>
            ))}
            {isHome && isVerified && (
              <button
                onClick={() => {
                  handleTeachMeClick();
                  setIsMenuOpen(false);
                }}
                className={`block px-3 py-2 rounded-md text-base font-medium ${
                  plan === 'Yearly Plan'
                    ? 'bg-yellow-400 text-blue-900 hover:bg-yellow-500'
                    : plan === 'Monthly Plan'
                    ? 'bg-gray-400 text-blue-900 hover:bg-gray-500'
                    : 'bg-white text-blue-600 hover:bg-blue-50'
                }`}
              >
                Now Teach Me
              </button>
            )}
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;