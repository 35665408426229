// Footers.js

import React from 'react';
import { Footer } from 'flowbite-react';
import DarkLogo from '../res/img/darkLogo.svg';
import { company, websiteURL } from '../constants';
import { useNavigate } from 'react-router-dom';
import LogoComponent from './LogoComponent';

const Footers = () => {
  const storedTheme = sessionStorage.getItem('darkMode');

  const navigate = useNavigate();
  function redirectAbout() {
    navigate("/about");
  }
  function redirectContact() {
    navigate("/contact");
  }
  function redirectRefunds() {
    navigate("/refund");
  }
  function redirectPrivacy() {
    navigate("/privacy");
  }

  return (
    <Footer container className="shadow-none rounded-none bg-gradient-to-r from-blue-500 to-blue-700 text-white">
      <div className="w-full text-center">
        <div className="w-full justify-between sm:flex sm:items-center sm:justify-between">
          <Footer.Brand
            href={websiteURL}
            src={storedTheme === "true" ? DarkLogo : LogoComponent}
          />
          <Footer.LinkGroup>
            <p className="text-white font-bold mx-4 hover:text-gray-300 cursor-pointer" onClick={redirectAbout}>
              About
            </p>
            <p className="text-white font-bold mx-4 hover:text-gray-300 cursor-pointer" onClick={redirectPrivacy}>
              Privacy Policy
            </p>
            <p className="text-white font-bold mx-4 hover:text-gray-300 cursor-pointer" onClick={redirectContact}>
              Contact
            </p>
            <p className="text-white font-bold mx-4 hover:text-gray-300 cursor-pointer" onClick={redirectRefunds}>
              Refunds
            </p>
          </Footer.LinkGroup>
        </div>
        <Footer.Divider className="border-gray-300" />
        <Footer.Copyright
          className="text-white font-bold"
          href={websiteURL}
          by={company}
          year={2024}
        />
      </div>
    </Footer>
  );
};

export default Footers;