import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { HiPaperClip, HiUser, HiAcademicCap, HiOutlinePaperAirplane, HiDownload } from 'react-icons/hi';
import { jsPDF } from 'jspdf';
import Tesseract from 'tesseract.js';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import DOMPurify from 'dompurify';

function Chatbot() {
  const [messages, setMessages] = useState([
    {
      sender: 'bot',
      text: "Hello! I'm your AI study buddy, here to help you understand any subject or topic. What topic or subject would you like assistance with today?",
    },
  ]);
  const [inputText, setInputText] = useState('');
  const [extractedText, setExtractedText] = useState('');
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [uploadedFileURL, setUploadedFileURL] = useState('');
  const messagesEndRef = useRef(null);

  const predefinedPrompt = `
You are an advanced AI educational assistant created to provide detailed and clear explanations for students' questions across various subjects. Your main goal is to help the student understand the content provided from their uploaded document in conjunction with their question. Follow these guidelines:

1. Use the content provided from the student's uploaded document to answer their question.
2. Begin by acknowledging the uploaded document if applicable.
3. Provide a comprehensive and direct answer to address their question:
   - For math or science topics, break down your answer step-by-step and clarify each part.
   - For conceptual subjects, divide your explanation into clear, manageable parts.
   - Use examples, analogies, or visual descriptions to make complex ideas easier to understand.
4. After each explanation, ask a follow-up question like "Is there any specific part of this explanation you'd like to go over again?" or "Do you need more examples on this topic?"
5. Continue this interactive, supportive style to ensure clarity and help them feel comfortable asking further questions.
6. If you're unsure of any part, be transparent but provide the most accurate response possible.
7. Maintain a supportive, encouraging tone to promote curiosity and help them feel comfortable asking follow-up questions.

`;

  const sendMessage = async () => {
    if (inputText.trim() === '' && !extractedText) return;

    const userMessage = { sender: 'user', text: inputText };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInputText('');

    try {
      let fullPrompt = predefinedPrompt;

      if (extractedText.trim()) {
        fullPrompt += `
Content from the student's uploaded document:

${extractedText}

Student's question: ${inputText}
        `;
      } else {
        fullPrompt += `Student's question: ${inputText}`;
      }

      const response = await axios.post('https://aiteacher.learnrithm.com/api/chat', { prompt: fullPrompt });
      const botMessage = {
        sender: 'bot',
        text:
          response.data.text ||
          "I apologize, but I couldn't generate a response. Could you please rephrase your question?",
      };
      setMessages((prevMessages) => [...prevMessages, botMessage]);

      // Clear the uploaded data after processing
      setExtractedText('');
      setUploadedFileName('');
      setUploadedFileURL('');
    } catch (error) {
      console.error('Error:', error);
      const errorMessage = {
        sender: 'bot',
        text: 'I apologize, but I encountered an error while processing your request. Could you please try asking your question again?',
      };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    }
  };

  const handleFileUpload = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      // Clear previous extraction
      setExtractedText('');
      if (uploadedFile.type === 'application/pdf') {
        extractTextFromPDF(uploadedFile);
      } else {
        extractTextFromImage(uploadedFile);
      }
    }
  };

  const extractTextFromPDF = (file) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const pdfData = new Uint8Array(event.target.result);
      pdfjsLib.getDocument({ data: pdfData }).promise.then((pdf) => {
        let textContent = '';
        const numPages = pdf.numPages;
        const promises = [];

        const processPage = (pageNum) => {
          return pdf.getPage(pageNum).then((page) => {
            return page.getTextContent().then((text) => {
              text.items.forEach((item) => {
                textContent += item.str + ' ';
              });
            });
          });
        };

        for (let pageNum = 1; pageNum <= numPages; pageNum++) {
          promises.push(processPage(pageNum));
        }

        Promise.all(promises).then(() => {
          if (textContent.trim()) {
            setExtractedText(textContent);
            setUploadedFileName(file.name);
            const fileURL = URL.createObjectURL(file);
            setUploadedFileURL(fileURL);
          } else {
            alert('No text detected in the PDF.');
          }
        });
      });
    };
    reader.readAsArrayBuffer(file);
  };

  const extractTextFromImage = (file) => {
    Tesseract.recognize(file, 'eng', {
      logger: (m) => console.log(m),
    })
      .then(({ data: { text } }) => {
        if (text.trim()) {
          setExtractedText(text);
          setUploadedFileName(file.name);
          const fileURL = URL.createObjectURL(file);
          setUploadedFileURL(fileURL);
        } else {
          alert('No text detected in the image.');
        }
      })
      .catch((err) => {
        console.error('Error extracting text:', err);
        alert('Error extracting text from the file.');
      });
  };

  const generatePDFSummary = async () => {
    try {
      const analysisPrompt = `
Based on the following conversation history between the user and the AI, create a complete, cohesive summary that covers the key topics discussed. Dive deeper into the main topics as if summarizing for a study guide. Ensure the summary:
- Covers main points and gives examples.
- Explains key concepts thoroughly and clearly.
- Arranges information in a logical, structured format suitable for a study guide.

Conversation history:
${messages.map((msg) => (msg.sender === 'user' ? 'User: ' : 'AI: ') + msg.text).join('\n')}`;

      const response = await axios.post('https://aiteacher.learnrithm.com/api/chat', { prompt: analysisPrompt });
      let summaryText = response.data.text || 'Could not generate summary.';

      // Remove HTML tags from the summary
      summaryText = summaryText.replace(/<\/?[^>]+(>|$)/g, '');

      // Generate the PDF
      const doc = new jsPDF();
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(18);
      doc.text('AI Study Companion - In-Depth Study Guide', 10, 20);
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(12);
      doc.setTextColor(100);

      const lines = doc.splitTextToSize(summaryText, 180);
      doc.text(lines, 10, 30);

      doc.save('AI_Study_Companion_In_Depth_Summary.pdf');
    } catch (error) {
      console.error('Error generating summary PDF:', error);
      alert('There was an error generating the summary PDF.');
    }
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <div className="flex flex-col h-screen bg-gradient-to-r from-blue-500 to-blue-700">
      {/* Header */}
      <div className="bg-white shadow-md p-2">
        <h1 className="text-xl font-bold text-gray-800 text-center">AI Study Companion</h1>
      </div>

      {/* Chat Messages */}
      <div className="flex-1 overflow-y-auto p-2 space-y-2">
        {messages.map((msg, index) => (
          <div key={index} className={`flex ${msg.sender === 'user' ? 'justify-end' : 'justify-start'}`}>
            <div
              className={`flex items-end space-x-1 ${
                msg.sender === 'user' ? 'flex-row-reverse space-x-reverse' : 'flex-row'
              }`}
            >
              <div
                className={`w-8 h-8 rounded-full flex items-center justify-center ${
                  msg.sender === 'user' ? 'bg-blue-500' : 'bg-green-500'
                }`}
              >
                <span className="text-white text-lg">
                  {msg.sender === 'user' ? <HiUser /> : <HiAcademicCap />}
                </span>
              </div>
              <div className="bg-gray-200 p-2 rounded-lg max-w-xs">
                {msg.fileURL ? (
                  <a href={msg.fileURL} target="_blank" rel="noopener noreferrer">
                    <img src={msg.fileURL} alt="Uploaded file" className="max-w-full h-auto mb-1" />
                  </a>
                ) : null}
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(msg.text) }}></div>
              </div>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      {/* Input Area */}
      <div className="bg-white p-2 shadow-md flex flex-col space-y-2 sticky bottom-0">
        {uploadedFileName && (
          <div className="bg-gray-200 p-2 rounded-lg max-w-xs mx-auto">
            <a href={uploadedFileURL} target="_blank" rel="noopener noreferrer">
              <img src={uploadedFileURL} alt="Uploaded file" className="max-w-full h-auto mb-1" />
            </a>
            <div className="text-gray-500 text-xs text-center">{uploadedFileName}</div>
          </div>
        )}
        <div className="flex items-center space-x-1">
          <div className="flex items-center flex-1 relative">
            <input
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              placeholder="Ask a question..."
              className="flex-1 pr-10 border rounded-lg p-2"
              onKeyPress={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  sendMessage();
                }
              }}
            />
            <label htmlFor="file-upload" className="absolute right-8 cursor-pointer text-blue-500">
              <HiPaperClip size={24} />
            </label>
            <input
              type="file"
              accept="application/pdf,image/*"
              onChange={handleFileUpload}
              className="hidden"
              id="file-upload"
            />
            <button onClick={sendMessage} className="absolute right-2 text-blue-500">
              <HiOutlinePaperAirplane size={24} />
            </button>
          </div>
          <button onClick={generatePDFSummary} className="text-blue-500 ml-2">
            <HiDownload size={24} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Chatbot;